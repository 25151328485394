import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import ServicesList from "./ServicesList";
import { useGlobalContext } from "./context/Context";

// icons 
import { IoIosCloseCircleOutline } from "react-icons/io";

const TopNav = () => {
    const location = useLocation()
    const [isMobileNav, setIsMobileNav] = useState(false)
    const { openServices, isServicesOpen, closeServices } = useGlobalContext()

    const openMobileMenu = () => {
        setIsMobileNav(true)
    }

    const closeMobileMenu = () => {
        setIsMobileNav(false)
    }

    return (
        <>
            <nav>
                {isMobileNav && (
                    <div className="mobile-menu">
                        <div className="top">
                            <Link to={"/"} className="logo">
                                <img src="/logo2.svg" alt="" />
                            </Link>
                            <div className="menu-bar">
                                <button onClick={closeMobileMenu}>
                                    <IoIosCloseCircleOutline />
                                </button>
                            </div>
                        </div>
                        <div className="links-container">
                            <ul className="mobile-links">
                                <li onClick={closeMobileMenu}>
                                    <Link to='/' className={`${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
                                    {location.pathname === '/' && (
                                        <div className="underline"></div>
                                    )}
                                </li>
                                <li onClick={closeMobileMenu}>
                                    <Link to='/services' className={`${location.pathname === '/services' ? 'active' : ''}`}>Services</Link>
                                    {location.pathname === '/services' && (
                                        <div className="underline"></div>
                                    )}
                                </li>
                                <li onClick={closeMobileMenu}>
                                    <Link to='/about-us' className={`${location.pathname === '/about-us' ? 'active' : ''}`}>About Us</Link>
                                    {location.pathname === '/about-us' && (
                                        <div className="underline"></div>
                                    )}
                                </li>
                                <li onClick={closeMobileMenu}>
                                    <Link to='/faqs' className={`${location.pathname === '/faqs' ? 'active' : ''}`}>Faqs</Link>
                                    {location.pathname === '/faqs' && (
                                        <div className="underline"></div>
                                    )}
                                </li>
                                {/* <li onClick={closeMobileMenu}>
                                    <Link to='/careers' className={`${location.pathname === '/careers' ? 'active' : ''}`}>Careers</Link>
                                    {location.pathname === '/careers' && (
                                        <div className="underline"></div>
                                    )}
                                </li> */}
                                <li onClick={closeMobileMenu}>
                                    <Link to='/testimonials' className={`${location.pathname === '/testimonials' ? 'active' : ''}`}>Testimonials</Link>
                                    {location.pathname === '/testimonials' && (
                                        <div className="underline"></div>
                                    )}
                                </li>
                                <li onClick={closeMobileMenu}>
                                    <Link to='/contact' className='contact-btn'>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
                <div className="container">
                    <div className="row">
                        <Link to={"/"} className="logo">
                            <img src="/logo2.svg" alt="" />
                        </Link>
                        <ul className='links'>
                            <li onClick={closeServices}>
                                <Link to='/' className={`${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
                                {location.pathname === '/' && (
                                    <div className="underline"></div>
                                )}
                            </li>
                            <li onClick={openServices}>
                                <Link className={`${(location.pathname === '/services' || location.pathname === '/facilityServices') ? 'active' : ''}`}>Services</Link>
                                {(location.pathname === '/services' || location.pathname === '/facilityServices') && (
                                    <div className="underline"></div>
                                )}
                            </li>
                            <li onClick={closeServices}>
                                <Link to='/about-us' className={`${location.pathname === '/about-us' ? 'active' : ''}`}>About Us</Link>
                                {location.pathname === '/about-us' && (
                                    <div className="underline"></div>
                                )}
                            </li>
                            <li onClick={closeServices}>
                                <Link to='/faqs' className={`${location.pathname === '/faqs' ? 'active' : ''}`}>FAQS</Link>
                                {location.pathname === '/faqs' && (
                                    <div className="underline"></div>
                                )}
                            </li>
                            {/* <li onClick={closeServices}>
                                <Link to='/careers' className={`${location.pathname === '/careers' ? 'active' : ''}`}>Careers</Link>
                                {location.pathname === '/careers' && (
                                    <div className="underline"></div>
                                )}
                            </li> */}
                            <li onClick={closeServices}>
                                <Link to='/testimonials' className={`${location.pathname === '/testimonials' ? 'active' : ''}`}>Testimonials</Link>
                                {location.pathname === '/testimonials' && (
                                    <div className="underline"></div>
                                )}
                            </li>
                            <li onClick={closeServices}>
                                <Link to='/contact' className='contact-btn'>Contact Us</Link>
                            </li>
                        </ul>
                        <div className="menu-bar">
                            <button onClick={openMobileMenu}>
                                <img src="/images/menu.svg" alt="menu" />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            {isServicesOpen && (
                <ServicesList />
            )}
        </>
    )
}

export default TopNav
