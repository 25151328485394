// styles
import '../../styles/pages/home/home.css';

// components
import Contact from "./Contact"
import PatternVertical from "../PatternVertical"

// icons
import { FaArrowRight } from "react-icons/fa6";

const Home = () => {

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <div className="col col-1">
              <PatternVertical style={`pattern`} />
              <h1>Non-Emergency Medical Transportation</h1>
              {/* <p>We provide reliable transportation to those who require more than what public transportation has to offer. Staffed by professional drivers, we take pride in giving you the best care around!</p> */}
              <div className='contact'>
                <a href="/contact" className='contact-link'>Contact Us</a>
              </div>
            </div>
            <div className="col col-2">
              <div className='hero-1'>
                <img src='/images/hero-1.svg' className='img' />
                <div className="pattern-1"></div>
              </div>
              <div className='hero-2'>
                <img src="/images/hero-2.svg" alt="img" />
              </div>
              <div className='hero-3'>
                <div className="pattern-2"></div>
                <img src="/images/hero-3.svg" alt="" />
              </div>
              <div className='hero-4'>
                <PatternVertical style={`hero-pattern`} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='emergency-medical-transport'>
        <div className="container">
          <div className="row">
            <div className="col col-1">
              <div className='img-1'>
                <img src="/images/wheel-transport-2.svg" alt="" />
              </div>
              <div className='img-2'>
                <img src="/images/wheel-transport.svg" alt="" />
              </div>
              <div className="col-pattern">
                <PatternVertical style={`pattern`} />
              </div>
            </div>
            <div className="col col-2">
              <div className="title">
                <PatternVertical style={`title-pattern`} />
                <h1>NON-EMERGENCY MEDICAL TRANSPORT</h1>
              </div>
              <p>We provide reliable transportation to those who require more than what public transportation has to offer. Staffed by professional drivers, we take pride in giving you the best care around!  We are open from 8:00 a.m. to 5:00 p.m., but we offer after-hour and weekend appointments with prior notice</p>
              <div>
                <a href="/about-us" className='about-us-link'>
                  <span>More About Us</span> <FaArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='assurance'>
        <div className="container">
          <p>Now Accepting <br /> Medicaid and Aetna</p>
        </div>
      </section>

      <section className="services">  
        <div className="container">
          <div className="title">
            <PatternVertical style={`title-pattern`} />
            <h1>Services</h1>
          </div>
          <div className="row">
            <div className="col col-reverse">
              <img src="/images/Wheelchair 2.jpg" alt="" />
              <div className="col-title">
                <PatternVertical style={`title-pattern`} />
                <h2>Bariatric <br /> Transport</h2>
              </div>
            </div>
            <div className="col">
              <div className="col-title">
                <PatternVertical style={`title-pattern`} />
                <h2>Ambulatory <br /> Transport</h2>
              </div>
              <img src="/images/Ambulatory 2.jpg" alt="" />
            </div>
            <div className="col col-reverse">
              <img src="/images/Bariatric 2.jpg" alt="" />
              <div className="col-title">
                <PatternVertical style={`title-pattern`} />
                <h2>Wheelchair <br /> Transport</h2>
              </div>
            </div>
            <div className="col">
              <div className="col-title">
                <PatternVertical style={`title-pattern`} />
                <h2>Stretcher <br /> Transport</h2>
              </div>
              <img src="/images/Stretcher 2.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="facility-services">
        <div className="container">
          <div className="col">
            <h1>Facility Services</h1>
            <p>We work with multiple facilities serving some or all of their non-<br />emergency medical transportation needs.</p>
            <div className="link">
              <a href="/facilityServices"><span>Know more</span> <FaArrowRight /></a>
            </div>
          </div>
        </div>
      </section>

      <section className='why-section'>
        <div className="container">
          <div className="row">
            <div className="col">
              <img src="/images/man-wheel-chair.jpg" alt="" />
            </div>
            <div className="col">
              <div className="title">
                <PatternVertical style={`title-pattern`} />
                <h1>WHY COHESIVE <br /> MEDIRYDE?</h1>
              </div>
              <p>At Cohesive MediRyde, we understand that reliable and affordable transportation plays an integral role in healthcare and  we are honored to be a part of that process. Therefore, we hold our personnel and equipment to the highest standards.</p>
              <p>Our transportation specialists are trained and certified in basic life support (BLS), defensive driving, and passenger service and safety (PASS). We arrive promptly to ensure you have plenty of time to make it to scheduled appointments, always conduct ourselves professionally, and treat passengers with the utmost respect and care.Our vehicles are kept in peak condition and impeccably clean ensuring comfort and convenience for each passenger.</p>
            </div>
          </div>
        </div>
      </section>

      <Contact />
    </>

  )
}

export default Home
