import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// icons
import { FaFacebookF } from "react-icons/fa";
import { IoCall, IoMailOutline, IoLocationOutline } from "react-icons/io5";

const Footer = () => {
    const [date, setDate] = useState('')

    useEffect(() => {
        const getDate = new Date().getFullYear()
        setDate(getDate)
    }, [date])

    return (
        <footer>
            <div className="container">
                <div className="footer-row">
                    <div className="col col-1">
                        <Link to={"/"} className="logo">
                            <img src="/logo.svg" alt="logo" />
                        </Link>
                        <p>We provide reliable transportation to those who require more than what public transportation has to offer. </p>
                    </div>
                    <div className="col">
                        <h2>Social Links</h2>
                        <a href="https://www.facebook.com/Cohesive-MediRyde-190717045148983/" className="facebook-link">
                            <FaFacebookF />
                        </a>
                    </div>
                    <div className="col">
                        <h2>Quick links</h2>
                        <div className="links">
                            <div className="link">
                                <a href="/about-us">About</a>
                                <a href="/services">Services</a>
                            </div>
                            <div className="link">
                                <a href="/faqs">FAQS</a>
                                <a href="/testimonials">Testimonials</a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="col-row">
                            <div className="icon">
                                <IoMailOutline />
                            </div>
                            <div className="desc">
                                <p>mediryde@chmcok.com</p>
                            </div>
                        </div>
                        <div className="col-row-2">
                            <div className="icon">
                                <IoLocationOutline />
                            </div>
                            <div className="desc">
                                <p>43155 45th Street, Shawnee, <br /> OK 74804 </p>
                            </div>
                        </div>
                        <div className="col-row-2">
                            <div className="icon">
                                <IoCall />
                            </div>
                            <div className="desc">
                                <p>(405) 395-4474</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="underline"></div>
            <div className="copyright">
                <p>&copy; {date} Cohesive MediRyde, All Rights Reserved</p>
            </div>
        </footer>
    )
}

export default Footer
