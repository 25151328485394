import { useState, useEffect } from "react"

// styles
import '../../styles/pages/testimonials/testimonials.css'

// components
import PatternVertical from '../PatternVertical'
import { testimonialss } from "../../constants"


// icons
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState(testimonialss)
    const [index, setIndex] = useState(0)

    // useEffect(() => {
    //     const lastIndex = testimonials.length - 1

    //     if (index < 0) {
    //         setIndex(lastIndex)
    //     }

    //     if (index > lastIndex) {
    //         setIndex(0)
    //     }

    // }, [index, testimonials])

    // useEffect(() => {
    //    let slider = setInterval(() => {
    //         setIndex(index + 1)
    //     }, 5000)

    //     return () => clearInterval(slider)
    // }, [index])

    return (
        <section className="testimonials">
            <div className="header">
                <h3>Testimonials</h3>
                <p>What you say about us to others means <br /> everything !</p>
            </div>
            <div className="container">
                <div className="testimonials-container">
                    {testimonials.map((testimonial, testIndex) => {
                        let position = 'nextSlide'
                        if (testIndex === index) {
                            position = 'activeSlide'
                        }
                        if (testIndex === index - 1 ||
                            (index === 0 && testIndex === index.length - 1)
                        ) {
                            position = 'lastSlide'
                        }
                        return (
                            <article className={position}>
                                <div className="pattern-container">
                                    <PatternVertical style={`pattern`} />
                                </div>
                                <h2>{testimonial.name}</h2>
                                <p>{testimonial.description}</p>
                            </article>
                        )
                    })}
                    <button className="prev" onClick={() => setIndex(index - 1)}>
                        <TfiArrowCircleLeft />
                    </button>
                    <button className="next" onClick={() => setIndex(index + 1)}>
                        <TfiArrowCircleRight />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
